
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../../features/auth/authSlice'
import { Mutex } from 'async-mutex';


const mutex = new Mutex();
const baseUrl = 'https://pronia.indonesiapower.co.id:3000/api/items_realtime'

const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        // const token = getState().auth.token
        // if (token) {
        //     headers.set("authorization", `Bearer ${token}`)
        // }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions)
    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})


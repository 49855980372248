import { createSlice } from "@reduxjs/toolkit";

const navbarSlice = createSlice({
  name: 'set',
  initialState: {
    sidebarShow: true
  },
  reducers: {
    showHideNavbar: (state, { type, ...rest }) => {
      switch (rest.payload.type) {
        case 'set':
          state.sidebarShow = rest.payload.sidebarShow;
          if (rest.payload.sidebarUnfoldable) {
            delete state.sidebarShow;
            state['sidebarUnfoldable'] = rest.payload.sidebarUnfoldable
          }
          else {
            delete state.sidebarUnfoldable;
          }
        default:
          if (state.sidebarShow) {
            let default_sidebarShow = state.sidebarShow
            state.sidebarShow = default_sidebarShow
          }
          if (state.sidebarUnfoldable) {
            let default_sidebarUnfoldable = state.sidebarUnfoldable
            state.sidebarUnfoldable = default_sidebarUnfoldable
          }
      }
    }
  }
})

export const { showHideNavbar } = navbarSlice.actions;
export default navbarSlice.reducer;

import { combineReducers } from "redux";
import authSlice from "./auth/authSlice";
import ProniaMuaraJawaSlice from "./muaraJawa/ProniaMuaraJawaSlice";
import { apiSlice } from "../app/api/apiSlice"
import navbarReducer from "./navbar/navbarSlice";

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  navbar:navbarReducer,
  auth:authSlice,
  proniaMuaraJawaSlice:ProniaMuaraJawaSlice
})

export default rootReducer;

// import { configureStore } from "@reduxjs/toolkit"
// import storage from 'redux-persist/lib/storage'
// import { apiSlice } from "../app/api/apiSlice"
// import rootReducer from './rootReducer'
// import { persistReducer } from 'redux-persist'


// const persistConfig = {
//   key: 'root',
//   storage
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);


// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware().concat(apiSlice.middleware),
//   devTools: true
// })

// export default store;

import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "../app/api/apiSlice"
import rootReducer from './rootReducer'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat([
    apiSlice.middleware,
    /**
     * TODO other middleware masukkan disini
     */
    // userApi.middleware,
    // // Add the PostApi middleware to the store
    // postApi.middleware,
  ]),
  devTools: true
})

export default store;


import { createSlice } from "@reduxjs/toolkit"

const proniaMuaraJawaSlice = createSlice({
    name: 'ProniaMuaraJawa',
    initialState: {value:[]},
    reducers: {
      setProniaMuaraJawa: (state, action) => {
           state= action.payload
        }
    },
})

export const { setProniaMuaraJawa} = proniaMuaraJawaSlice.actions

export default proniaMuaraJawaSlice.reducer
